import { createApp } from 'vue';
import pinia from '@/stores/pinia';
import i18n from '@/i18n';
import router from '@/router';
import './registerServiceWorker';
import features from '@/utils/features';
import '@/utils/array';
import { configureTrackingError } from '@/log';

import App from './App.vue';
import { loadBaseComponents } from '@/startup/base-components';
import VueGtm from 'vue-gtm';

const app = createApp(App);

configureTrackingError();

if (!window.MediaRecorder) {
  const polyfilledMediaRecorder = require('audio-recorder-polyfill');
  if (polyfilledMediaRecorder.notSupported) {
    console.warn('MediaRecorder could not be polyfilled');
  } else {
    window.MediaRecorder = polyfilledMediaRecorder;
  }
}

if (features.hasTouchSupport()) {
  require('./utils/dragDropTouch');
}

(window as any).VIDEOJS_NO_DYNAMIC_STYLE = true;

loadBaseComponents(app);

app.use(pinia).use(i18n).use(router);

if (process.env.ACTIVATE_TRACKING_APP && process.env.ANALYTICS_APP_KEY) {
  app.use(VueGtm, {
    id: process.env.ANALYTICS_APP_KEY,
    vueRouter: router,
    enabled: true,
    debug: false,
    loadScript: true,
    trackOnNextTick: false
  });
}

router.isReady().then(() => {
  const { body } = document;

  if (process.env.ACTIVATE_CHAT_APP) {
    try {
      body.appendChild(
        document.createComment('Start of fassilio Zendesk Widget script')
      );
      const element = document.createElement('script');
      element.setAttribute('id', 'ze-snippet');
      element.setAttribute(
        'src',
        'https://static.zdassets.com/ekr/snippet.js?key=7d1cc6f1-ed6f-4912-8d24-d6ca45decbb5'
      );
      body.appendChild(element);
      body.appendChild(
        document.createComment('End of fassilio Zendesk Widget script')
      );
    } catch (e) {
      console.error('Initialisation ZenDesk', e);
    }
  }

  app.mount('#app');
});
