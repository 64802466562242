import { enCA, frCA } from 'date-fns/locale';
import dateFormatter from 'date-fns/format';
import i18n from '@/i18n/index';

export const addSeconds = function (date, s) {
  date.setTime(date.getTime() + s * 1000);
  return date;
};

export const addMinutes = function (date, h) {
  if (!date) return date;

  date.setTime(date.getTime() + h * 60 * 1000);
  return date;
};

export const addHours = function (date, h) {
  if (!date) return date;

  date.setTime(date.getTime() + h * 60 * 60 * 1000);
  return date;
};

const getDates = (date1, date2) => {
  let d1 = date1;
  let d2 = date2;

  if (date1 < date2) {
    d1 = date2;
    d2 = date1;
  }

  return { d1, d2 };
};

export const secondsDiff = (date1, date2) => {
  const { d1, d2 } = getDates(date1, date2);

  const diff = d1.getTime() - d2.getTime();
  return diff / 1000;
};

export const minutesDiff = (date1, date2) => {
  const { d1, d2 } = getDates(date1, date2);

  const diff = d1.getTime() - d2.getTime();
  return diff / 1000 / 60;
};

export const hoursDiff = (date1, date2) => {
  const { d1, d2 } = getDates(date1, date2);

  const diff = d1.getTime() - d2.getTime();
  return diff / 1000 / 60 / 60;
};

export const daysDiff = (date1, date2) => {
  const { d1, d2 } = getDates(date1, date2);

  const diff = d1.getTime() - d2.getTime();
  return diff / 1000 / 60 / 60 / 24;
};

export const monthsDiff = (date1, date2) => {
  const { d1, d2 } = getDates(date1, date2);

  let m =
    (d1.getFullYear() - d2.getFullYear()) * 12 +
    (d1.getMonth() - d2.getMonth());
  if (d1.getDate() < d2.getDate()) --m;
  return m;
};

export const yearsDiff = (date1, date2) => {
  const { d1, d2 } = getDates(date1, date2);

  return d1.getFullYear() - d2.getFullYear();
};

export const getCommentInfoDate = (date, locale) => {
  const now = new Date();
  if (
    now.getDate() === date.getDate() &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear()
  ) {
    return dateFormatter(date, 'HH:mm');
  } else if (daysDiff(now, date) < 7) {
    return dateFormatter(date, 'EEEE HH:mm', {
      locale: locale === 'fr' ? frCA : enCA
    });
  } else if (now.getFullYear() === date.getFullYear()) {
    return dateFormatter(date, 'dd/MM HH:mm');
  } else return dateFormatter(date, 'dd/MM/yyyy HH:mm');
};

export const getSince = (date) => {
  const { d1, d2 } = getDates(new Date(), date);
  const seconds = secondsDiff(d1, d2);
  if (seconds < 60) return { value: Math.round(seconds), type: 'second' };

  const minutes = seconds / 60;
  if (minutes < 60) return { value: Math.round(minutes), type: 'minute' };

  const hours = minutes / 60;
  if (hours < 24) return { value: Math.round(hours), type: 'hour' };

  const months = monthsDiff(d1, d2);
  if (months < 1) return { value: Math.round(hours / 24), type: 'day' };
  else if (months < 12) return { value: Math.round(months), type: 'month' };

  return { value: yearsDiff(d1, d2), type: 'year' };
};

export const getRemainingTime = (date, date2) => {
  const { d1, d2 } = getDates(date, date2 || new Date());
  let totalSeconds = secondsDiff(d1, d2);

  const days = parseInt(totalSeconds / 60 / 60 / 24);
  totalSeconds -= days * 60 * 60 * 24;

  const hours = parseInt(totalSeconds / 60 / 60);
  totalSeconds -= hours * 60 * 60;

  const minutes = parseInt(totalSeconds / 60);
  totalSeconds -= minutes * 60;

  const seconds = parseInt(totalSeconds);

  return { days, hours, minutes, seconds };
};

export const today = () => {
  const d = new Date();
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

export const now = (seconds = false) => {
  const d = new Date();
  const s = seconds ? d.getSeconds() : 0;
  return new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    s
  );
};

export const toDisplay = (date, format = 'yyyy-MM-dd', lang) => {
  return dateFormatter(date, format, {
    locale: lang === 'fr' ? frCA : lang === 'en' ? enCA : null
  });
};

export const formatDateDiff = (date1, date2) => {
  let diff = Math.abs(date2 - date1);

  let days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -= days * (1000 * 60 * 60 * 24);

  let hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);

  let minutes = Math.floor(diff / (1000 * 60));
  diff -= minutes * (1000 * 60);

  let seconds = Math.floor(diff / 1000);

  const pad = (v) => v.toString().padStart(2, '0');

  let format = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  if (hours > 0) format = `${days}${i18n.global.t('global.dates.d')} ${format}`;
  return format;
};
