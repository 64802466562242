<template>
  <BaseDialogue
    class="share-asset-form-dialogue share-asset-form-dialogue--share-asset"
    :class="$attrs.class"
    size="small"
    :title="title"
    :showOk="true"
    :showCancel="showCancel"
    :disableOk="!isValid"
    @ok="ok()"
    @cancel="back(true)"
  >
    <template #ok>{{ okButtonText }}</template>
    <template #cancel>{{ cancelButtonText }}</template>

    <div class="share-asset-form-dialogue__main">
      <div class="share-asset-form-dialogue__main--content">
        <transition :name="transitionName" @afterEnter="focus">
          <div
            key="base"
            v-if="step === 'base'"
            class="form-element share-asset-form-dialogue__base"
            @keydown.enter="ok"
          >
            <BorderField>
              <BaseInput
                ref="name-input"
                class="label-bold"
                :label="$t('shareAssetForm.name')"
                name="name"
                v-model.trim="form.name"
              />
            </BorderField>

            <BorderField v-if="mode === 'edit'">
              <BaseInput
                ref="link-input"
                class="label-bold"
                :label="$t('shareAssetForm.link')"
                :modelValue="link"
                @v-click="selectLink"
                isReadOnly
              >
                <template #custom>
                  <div
                    class="form-field__custom share-asset-form-dialogue__link--copy"
                    @click.prevent.stop="copyToClipboard"
                  >
                    <CopyIcon size="small" />
                  </div>
                </template>
              </BaseInput>

              <div v-if="qrCode" class="share-asset-form-dialogue--qrcode">
                <img :src="qrCode" />
                <div>
                  <a :href="qrCode" download="qr-code.png">{{
                    $t('shareAssetForm.download')
                  }}</a>
                </div>
              </div>
            </BorderField>

            <BorderField>
              <div class="share-asset-form-dialogue__expiry-date">
                <BaseDatetimeInput
                  class="label-bold"
                  :label="$t('shareAssetForm.validUntil')"
                  name="expiryDate"
                  v-model="form.expiryDate"
                  :minDate="minExpiryDate"
                  :readonly="activeDialogue === 'set-password'"
                  teleport
                  @open="refreshMinExpiryDate"
                />
              </div>
            </BorderField>

            <BorderField v-if="displayPassword">
              <div
                class="form-field label-bold share-asset-form-dialogue__password"
              >
                <div class="form-field__label">
                  {{ $t('shareAssetForm.password.label') }}
                </div>

                <div class="share-asset-form-dialogue__password__actions">
                  <CallToAction size="auto" @v-click="executeActionPassord">
                    {{ titleActionPassword }}
                  </CallToAction>
                  <CallToAction
                    v-if="displayDeletePassword"
                    size="auto"
                    @v-click="form.deletePassword = true"
                  >
                    {{ $t('shareAssetForm.password.delete') }}
                  </CallToAction>
                </div>

                <div
                  v-if="displayPasswordEditMessage"
                  class="share-asset-form-dialogue__password--edit-message"
                >
                  {{ $t('shareAssetForm.password.editMessage') }}
                </div>
              </div>
            </BorderField>

            <BorderField v-if="displayPersonalizeEbrochureButton">
              <div class="share-asset-form-dialogue__customize-share">
                <div class="share-asset-form-dialogue--action">
                  <CallToAction size="auto" @v-click="personalizeEbrochure">
                    {{ $t('shareAssetForm.personalizeEbrochure') }}
                  </CallToAction>
                </div>
              </div>
            </BorderField>
          </div>

          <div
            key="named"
            v-else-if="step === 'named'"
            class="form-element share-asset-form-dialogue__base"
            @keydown.enter="ok"
          >
            <BorderField>
              <BaseInput
                ref="firstname-input"
                class="label-bold"
                :label="$t('shareAssetForm.firstName')"
                name="firstname"
                v-model.trim="form.firstName"
                :maxlength="255"
              />
            </BorderField>
            <BorderField>
              <BaseInput
                class="label-bold"
                :label="$t('shareAssetForm.lastName')"
                name="lastname"
                v-model.trim="form.lastName"
                :maxlength="255"
              />
            </BorderField>
            <BorderField>
              <div class="share-asset-form-dialogue--email">
                <BaseInput
                  class="label-bold"
                  :label="$t('shareAssetForm.email')"
                  type="email"
                  name="email"
                  v-model.trim="form.email"
                  :maxlength="255"
                  :isReadOnly="mode === 'edit'"
                />
                <div class="share-asset-form-dialogue--note">
                  {{ $t('shareAssetForm.note.email') }}
                </div>
              </div>
            </BorderField>
            <BorderField>
              <div class="form-field share-asset-form-dialogue__language">
                {{ $t('shareAssetForm.language') }}
              </div>
              <div class="share-asset-form-dialogue--note">
                {{ $t('shareAssetForm.note.language') }}
              </div>
              <div class="form-field share-asset-form-dialogue__language-list">
                <BaseRadio
                  class="form-field share-asset-form-dialogue__language-list--item"
                  v-for="item in languages"
                  :key="item.id"
                  :label="item.text"
                  name="language"
                  :value="item.id"
                  v-model="form.lang"
                />
              </div>
            </BorderField>

            <BorderField v-if="mode === 'edit'">
              <BaseInput
                ref="link-input"
                class="label-bold"
                :label="$t('shareAssetForm.link')"
                :modelValue="link"
                @v-click="selectLink"
                isReadOnly
              >
                <template #custom>
                  <div
                    class="form-field__custom share-asset-form-dialogue__link--copy"
                    @click.prevent.stop="copyToClipboard"
                  >
                    <CopyIcon size="small" />
                  </div>
                </template>
              </BaseInput>

              <div v-if="qrCode" class="share-asset-form-dialogue--qrcode">
                <img :src="qrCode" />
                <div>
                  <a :href="qrCode" download="qr-code.png">{{
                    $t('shareAssetForm.download')
                  }}</a>
                </div>
              </div>
            </BorderField>

            <BorderField v-if="displayPersonalizeEbrochureButton">
              <div class="share-asset-form-dialogue__customize-share">
                <div class="share-asset-form-dialogue--action">
                  <CallToAction size="auto" @v-click="personalizeEbrochure">
                    {{ $t('shareAssetForm.personalizeEbrochure') }}
                  </CallToAction>
                </div>
              </div>
            </BorderField>
          </div>

          <div
            key="creation-next"
            v-else-if="step === 'creation-next'"
            class="form-element share-asset-form-dialogue__base"
            @keydown.enter="ok"
          >
            <div class="form-element label">
              {{ assetName }}
            </div>

            <BorderField v-if="displayPersonalizeEbrochureButton">
              <div class="share-asset-form-dialogue__customize-share">
                <div class="share-asset-form-dialogue--action">
                  <CallToAction size="auto" @v-click="personalizeEbrochure">
                    {{ $t('shareAssetForm.personalizeEbrochure') }}
                  </CallToAction>
                </div>
              </div>
            </BorderField>

            <BorderField>
              <div class="form-element label upper">
                {{ $t('shareAssetForm.or') }}
              </div>
              <div class="form-element title">
                {{ $t('shareAssetForm.shareWithoutPersonalizing') }}
              </div>

              <BaseInput
                ref="link-input"
                class="label-bold"
                :label="$t('shareAssetForm.link')"
                :modelValue="link"
                @v-click="selectLink"
                isReadOnly
              >
                <template #custom>
                  <div
                    class="form-field__custom share-asset-form-dialogue__link--copy"
                    @click.prevent.stop="copyToClipboard"
                  >
                    <CopyIcon size="small" />
                  </div>
                </template>
              </BaseInput>

              <div v-if="qrCode" class="share-asset-form-dialogue--qrcode">
                <img :src="qrCode" />
                <div>
                  <a :href="qrCode" download="qr-code.png">{{
                    $t('shareAssetForm.download')
                  }}</a>
                </div>
              </div>
            </BorderField>
          </div>
        </transition>
      </div>

      <ToastAlert
        v-if="activeToast === 'info'"
        level="info"
        relative
        @cancel="toggleToast()"
      >
        {{ infoMessage }}
      </ToastAlert>
    </div>
  </BaseDialogue>

  <SetPasswordDialogue
    v-if="activeDialogue === 'set-password'"
    type="share-asset"
    @success="applyNewPassword"
    @cancel="toggleDialogue()"
  />
</template>

<script>
import '@/assets/stylesheets/components/_form.scss';
import { computed, nextTick, onMounted, reactive, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import { useShareAssetStore } from '@/stores/share-asset';
import { useToggleState } from '@/composables/toggle-state';

import BaseDialogue from '@/components/dialogues/BaseDialogue';
import BorderField from '@/components/forms/BorderField';
import ToastAlert from '@/components/toast/ToastAlert';
import SetPasswordDialogue from '@/components/forms/SetPasswordDialogue';
import CallToAction from '@/components/forms/CallToAction';
import CopyIcon from '@/components/icons/Copy';

import tools from '@/utils/tools';
import { now, toDisplay } from '@/utils/date';
import { SHARE_ASSET_TYPE } from '@/utils/types';

const STEP_BASE = 'base';
const STEP_NAMED = 'named';
const STEP_CREATION_NEXT = 'creation-next';

export default {
  inheritAttrs: false,
  name: 'ShareAssetFormDialogue',
  components: {
    BaseDialogue,
    BorderField,
    ToastAlert,
    SetPasswordDialogue,
    CallToAction,
    CopyIcon
  },
  props: {
    asset: {
      type: Object,
      default: null
    },
    editItem: {
      type: Object,
      default: () => ({})
    },
    isPaidNamedWebLink: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { t } = useI18n();
    const { displayError } = useAppStore();
    const { currentUser } = useAuthStore();
    const { modify, getQrCode } = useShareAssetStore();

    const { activeToast, toggleToast } = useToggleState('toast');
    const { activeDialogue, toggleDialogue } = useToggleState('dialogue');

    const $refs = {
      'link-input': ref(null),
      'name-input': ref(null),
      'firstname-input': ref(null)
    };

    const selectOnFocus = ref(!props.editItem.id);
    const isPaidNamedWebLink =
      props.editItem.type === 'named' || !!props.isPaidNamedWebLink;

    const defaultLang = currentUser.lang || 'en';
    const languages = [];
    if (defaultLang === 'en') {
      languages.push({ id: 'en', text: t('shareAssetForm.english') });
      languages.push({ id: 'fr', text: t('shareAssetForm.french') });
    } else {
      languages.push({ id: 'fr', text: t('shareAssetForm.french') });
      languages.push({ id: 'en', text: t('shareAssetForm.english') });
    }

    const ra = reactive({
      form: isPaidNamedWebLink
        ? {
            email: '',
            firstName: '',
            lastName: '',
            lang: defaultLang
          }
        : { expiryDate: null },
      item: { ...props.editItem },
      qrCode: null,

      selectedParticipant: null,
      submitted: false,
      step: isPaidNamedWebLink ? STEP_NAMED : STEP_BASE,
      transitionName: 'prev',
      minExpiryDate: now(),

      paidNamedWebLink: isPaidNamedWebLink,
      infoMessage: '',
      languages
    });

    const hasPassword = computed(() => {
      return ra.item.hasPassword && !ra.form.deletePassword;
    });
    const hasNewPassword = computed(() => {
      return !!(ra.form.newPassword || '').trim();
    });

    const assetName = computed(() => {
      return cp.mode.value === 'add' ? props.asset.name : ra.item.asset?.name;
    });

    const cp = {
      mode: computed(() => {
        return ra.item.id ? 'edit' : 'add';
      }),
      link: computed(() => {
        if (cp.mode.value !== 'edit') return '';

        const { origin } = document.location;
        const { href } = router.resolve({
          name: 'share',
          params: { hash: ra.item.hash }
        });
        return `${origin}/${href}`;
      }),
      isValid: computed(() => {
        if (ra.paidNamedWebLink) {
          if (!ra.form.firstName || !ra.form.lastName) return false;
          return tools.regExpEmail.test(ra.form.email);
        } else {
          return !!ra.form.name && !!ra.form.type;
        }
      }),
      title: computed(() => {
        if (!ra.item.id) {
          if (ra.step === STEP_BASE) return t('shareAssetForm.title.shareInfo');
          else return t('shareAssetForm.title.prospectInfo');
        } else if (ra.step === STEP_CREATION_NEXT) {
          return t('shareAssetForm.title.personalizeOrShare');
        }

        const mode = cp.mode.value;
        const assetType = mode === 'add' ? props.asset.type : ra.item.assetType;
        const key = `shareAssetForm.title.${mode}.${assetType}`;
        return t(key, [assetName.value]);
      }),
      displayPassword: computed(() => {
        return !ra.paidNamedWebLink;
      }),
      displayDeletePassword: computed(() => {
        return (
          !ra.paidNamedWebLink && hasPassword.value && !hasNewPassword.value
        );
      }),
      displayPasswordEditMessage: computed(() => {
        return (
          !ra.paidNamedWebLink &&
          (hasNewPassword.value || !!ra.form.deletePassword)
        );
      }),
      titleActionPassword: computed(() => {
        if (hasNewPassword.value || ra.form.deletePassword) {
          return t('shareAssetForm.password.cancel');
        } else if (hasPassword.value) {
          return t('shareAssetForm.password.edit');
        } else {
          return t('shareAssetForm.password.add');
        }
      }),
      okButtonText: computed(() => {
        if (!ra.item.id) return t('dialogue.footer.next');
        else if (ra.step === STEP_CREATION_NEXT)
          return t('dialogue.footer.close');
        else return t('dialogue.footer.ok');
      }),
      cancelButtonText: computed(() => {
        return props.editItem.id || cp.mode.value === 'add'
          ? t('dialogue.footer.cancel')
          : t('dialogue.footer.close');
      }),
      showCancel: computed(() => {
        return ra.step !== STEP_CREATION_NEXT;
      }),
      displayPersonalizeEbrochureButton: computed(() => {
        return cp.mode.value === 'edit' && ra.item.assetType === 'card';
      })
    };

    const init = () => {
      if (ra.paidNamedWebLink) {
        const {
          email = '',
          firstName = '',
          lastName = '',
          lang = defaultLang
        } = ra.item;

        ra.form = {
          type: 'named',
          email,
          lastName,
          firstName,
          lang
        };
      } else {
        const {
          name = `${props.asset.name} - ${toDisplay(new Date())}`,
          type = 'web',
          expiryDate = null
        } = ra.item;

        ra.form = {
          name,
          type,
          expiryDate: expiryDate ? new Date(expiryDate) : null,
          deletePassword: false,
          newPassword: null
        };
      }
    };

    const fns = {
      back() {
        switch (ra.step) {
          case STEP_BASE:
          case STEP_NAMED:
            emit('cancel');
            break;
        }

        ra.transitionName = 'prev';
      },
      copyToClipboard() {
        navigator.clipboard.writeText(cp.link.value);
        ra.infoMessage = t('shareAssetPage.linkCopied');
        toggleToast('info');
      },
      personalizeEbrochure() {
        router.push({
          name: SHARE_ASSET_TYPE,
          params: { id: ra.item.id }
        });
        emit('cancel');
        emit('personalizeEbrochure');
      },
      focus: () => {
        if (ra.step === STEP_BASE) {
          $refs['name-input'].value.focus(selectOnFocus.value);
        } else if (ra.step === STEP_NAMED) {
          $refs['firstname-input'].value.focus(selectOnFocus.value);
        }

        selectOnFocus.value = false;
      }
    };

    const refreshQrCode = async () => {
      ra.qrCode = null;
      if (ra.item?.id) {
        const { qrCode } = await getQrCode(ra.item.id);
        ra.qrCode = qrCode;
      }
    };

    const refreshData = async () => {
      init();
      await refreshQrCode();
    };

    // HOOKS
    onMounted(() => {
      refreshData();

      nextTick(() => {
        fns.focus();
      });
    });

    return {
      // REFS
      ...$refs,
      activeToast,
      toggleToast,

      // COMPOSABLES
      activeDialogue,
      toggleDialogue,

      // DATA
      ...toRefs(ra),

      // COMPUTED
      SHARE_ASSET_TYPE: computed(() => SHARE_ASSET_TYPE),
      assetName,
      ...cp,

      // METHODS
      ...fns,

      async ok() {
        switch (ra.step) {
          case STEP_BASE:
          case STEP_NAMED:
            if (ra.submitted) return;
            ra.submitted = true;

            try {
              let data;

              if (ra.item.id) {
                data = {
                  ...ra.form,
                  id: ra.item.id
                };
                delete data.email;
              } else {
                data = {
                  ...ra.form,
                  assetId: props.asset.id,
                  assetType: props.asset.type
                };
              }

              const entity = await modify(data);

              if (cp.mode.value === 'edit') {
                emit('success', entity);
              } else {
                ra.item = { ...entity };
                refreshData();
                if (ra.item.assetType === 'card') {
                  ra.step = STEP_CREATION_NEXT;
                  ra.infoMessage = t('shareAssetForm.shareCreatedSuccessfully');
                  nextTick(() => {
                    toggleToast('info');
                  });
                }
              }
            } catch (e) {
              console.error(e);
              displayError({ message: t('global.error') });
            } finally {
              ra.submitted = false;
            }

            break;
          case STEP_CREATION_NEXT:
            emit('success', ra.item);
            break;
        }
      },
      refreshMinExpiryDate() {
        ra.minExpiryDate = now();
      },
      executeActionPassord() {
        if (ra.form.deletePassword || hasNewPassword.value) {
          ra.form.deletePassword = false;
          ra.form.newPassword = '';
        } else {
          toggleDialogue('set-password');
        }
      },
      applyNewPassword(newPassword) {
        ra.form.newPassword = newPassword;
        toggleDialogue();
      },
      selectLink() {
        $refs['link-input'].value.select();
      }
    };
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';

.share-asset-form-dialogue {
  @include color-form();

  &__main {
    @include flexy($dir: column, $just: flex-start);
    flex: 1;
    overflow-x: hidden;
    position: relative;

    &--content {
      @include next-prev-transition();
      @include flexy($dir: column, $just: flex-start);
      flex: 1;
      overflow-x: hidden;
      position: relative;
    }
  }

  &__base {
    overflow-x: hidden;
  }

  &__language {
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-half;

    &-list {
      @include flexy($dir: row);

      &--item {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &__link--copy {
    @include flexy($align: center);
    height: 100%;
    padding: $spacing-quarter;
    margin-left: $spacing-half;

    > svg {
      fill: $color-base;
    }
    cursor: pointer;
  }

  &__expiry-date {
    @include flexy($align: flex-start);
  }

  &--action {
    @include flexy($just: center);
    margin: $spacing-base;

    > button {
      padding: $spacing-base;
      border: 1px solid $color-base;
      background-color: $color-base;
      margin-top: 0;
    }
  }

  &__password {
    &__actions {
      @include flexy();
      height: $vertical-rhythm;
      min-height: $vertical-rhythm;
      margin-top: $spacing-third;

      > * {
        margin-right: $spacing-half;
      }

      > button {
        background-color: $share-asset-base;
      }
    }

    &--edit-message {
      font-size: $font-size-small;
      font-weight: normal;
      font-style: italic;
      color: $font-important-color;
      margin: $spacing-half;
    }
  }

  &--qrcode {
    @include flexy($dir: column, $align: center);
    margin: $spacing-base;

    > img {
      max-width: 200px;
      height: auto;
      vertical-align: middle;
      border: 0;
    }

    > div {
      margin-top: $spacing-half;

      > a {
        color: $color-base;
      }
    }
  }

  &--email {
    .form-field {
      margin-bottom: $spacing-half;
    }

    padding-bottom: $spacing-half;
  }

  &--note {
    font-size: $font-size-small;
    font-weight: normal;
    font-style: italic;
    margin: $spacing-half $spacing-base;
  }

  .form-element {
    background-color: $background-light;
    height: 100%;
    width: 100%;
  }

  .form-element.title {
    @include flexy($just: center);
    font-size: $font-size-xlarge;
    font-weight: bold;
    margin: $spacing-base;
  }

  .form-element.label {
    @include flexy($just: center);
    font-weight: bold;
    margin: $spacing-half;
  }
  .form-element.label.upper {
    text-transform: uppercase;
  }

  .form-field__check {
    display: block;
    text-align: left;
  }
}
</style>
