<template>
  <div
    class="tile-media"
    :class="[
      `tile-media--${item.mediaType}`,
      { 'tile-media--disabled': disabled },
      { 'tile-media--hidden': item.hidden },
      $attrs.class
    ]"
  >
    <DblClickElement
      ref="content"
      :style="contentStyle"
      :class="[
        'tile-media__content',
        { 'tile-media__content--has-image-background': hasImageBackground },
        `tile-media__content__type--${item.mediaType}`,
        { 'tile-media__content--disabled': disabled }
      ]"
      :prevent="false"
      @click="contentClicked($event.touched)"
      @dblclick="contentDoubleClicked"
      @dbltouch="contentDoubleClicked"
    >
      <div v-if="item.mediaType === 'text'" class="tile-media__content--text">
        <div
          v-if="item.content"
          class="tile-media__content--text--html"
          v-html="item.content"
        ></div>
      </div>
      <div
        v-else-if="item.mediaType === 'note'"
        class="tile-media__content--note"
      >
        <div>{{ item.content }}</div>
      </div>
      <div v-else class="tile-media__content--inner">
        <div v-if="processing" class="tile-media__content__processing">
          <div class="tile-media__content__processing--icon">
            <LoadingIcon />
          </div>
          <div
            v-if="processingMessageTag"
            class="tile-media__content__processing--text"
          >
            {{ $t(`upload.${processingMessageTag}`) }}
          </div>
        </div>

        <div
          v-else-if="item.mediaType === 'video'"
          class="tile-media__content--video"
        >
          <VideoMediaIcon size="large2" />
        </div>

        <div
          v-else-if="item.mediaType === 'audio'"
          class="tile-media__content--audio"
        >
          <AudioControl
            ref="audio"
            :item="item"
            :disabled="disabled || selectable || draggable"
            @audio-action="audioEvent"
          />
        </div>

        <div
          v-else-if="item.mediaType === 'link'"
          class="tile-media__content--link"
        >
          <TileCallToActionMedia
            @click="mediaLinkClicked"
            :options="tileCallToActionMediaOptions"
          />
        </div>

        <div
          v-else-if="item.mediaType === 'file'"
          class="tile-media__content--file"
        >
          <div
            class="tile-media__content--file--pill"
            :class="{
              'tile-media__content--file--pill--has-image-background':
                hasFileBackgroundImage
            }"
          >
            <div v-if="fileExt">{{ fileExt }}</div>
            <FileMediaIcon v-else size="medium" />
          </div>
        </div>
      </div>

      <div
        v-if="!isShared"
        class="tile-media__content--top-left"
        @click.stop=""
      >
        <div class="tile-media__content--avatar">
          <AvatarPill :info="item.author" />
        </div>
        <div
          v-if="displayCommentIndicator"
          class="tile-media__content--comment"
        >
          <CommentIndicator :item="item" />
        </div>
      </div>
    </DblClickElement>

    <div v-if="displayBottom" class="tile-media__bottom">
      <div
        class="tile-media__bottom--icon"
        :class="`tile-media__bottom--icon--${item.mediaType}`"
      >
        <component :is="icon" size="medium2" />
      </div>

      <DblClickElement
        class="tile-media__bottom--name"
        :prevent="false"
        @dblclick="editMedia"
        @dbltouch="editMedia"
      >
        {{ item.name }}
      </DblClickElement>

      <div v-if="displayStateAndActions" class="tile-media__bottom__right">
        <TileAssetState
          v-if="displayAssetState"
          class="tile-media__bottom__right--state"
          :item="item"
        />
        <div
          v-if="displayActionButton"
          class="tile-media__bottom__right--options"
        >
          <button class="icon-button" @click="actionClicked">
            <ActionIcon size="small" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="displayNoteStateAndActions"
      class="tile-media__note-state-actions"
    >
      <TileAssetState
        v-if="displayAssetState && item.mediaType === 'note'"
        class="tile-media__note-state-actions--state"
        :item="item"
      />
      <div
        v-if="displayActionButton"
        class="tile-media__note-state-actions--options"
      >
        <button class="icon-button" @click="actionClicked">
          <ActionIcon size="small" />
        </button>
      </div>
    </div>

    <div v-if="selectable" class="tile-media--checkbox">
      <TileItemCheckBox :item="item" :type="item.type" />
    </div>

    <!-- Options dialogue -->
    <MediaOptionsDialogue
      v-if="activeDialogue === 'options'"
      :item="item"
      @navigate="$emit('navigate', $event)"
      @cancel="toggleDialogue()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useMediaStore } from '@/stores/media';
import LoadingIcon from '../icons/Loading';
import TileCallToActionMedia from '../media/TileCallToActionMedia.vue';
import TextMediaIcon from '../icons/TextMedia';
import PhotoMediaIcon from '../icons/PhotoMedia';
import VideoMediaIcon from '../icons/VideoMedia';
import AudioMediaIcon from '../icons/AudioMedia';
import LinkMediaIcon from '../icons/LinkMedia';
import FileMediaIcon from '../icons/FileMedia';
import NoteMediaIcon from '../icons/NoteMedia';
import DblClickElement from '../extensions/DblClickElement';
import AudioControl from '../controls/AudioControl';
import TileItemCheckBox from './TileItemCheckBox';
import AvatarPill from '../AvatarPill';
import CommentIndicator from '../comment/CommentIndicator';
import TileAssetState from './TileAssetState';
import ActionIcon from '../icons/Action';
import selectionClipboardMixin from '../mixins/selectionClipboard';
import toggleState from '../mixins/toggleState';
import assetMixin from '../mixins/asset';
import mediaProperties from '../mixins/mediaProperties';
import routerMixins from '../mixins/router';
import MediaOptionsDialogue from '../media/MediaOptionsDialogue';

const icons = {
  audio: AudioMediaIcon,
  file: FileMediaIcon,
  link: LinkMediaIcon,
  photo: PhotoMediaIcon,
  text: TextMediaIcon,
  video: VideoMediaIcon,
  note: NoteMediaIcon
};

export default {
  inheritAttrs: false,
  name: 'TileMedia',
  mixins: [
    assetMixin,
    mediaProperties,
    selectionClipboardMixin,
    toggleState('dialogue'),
    routerMixins
  ],
  components: {
    MediaOptionsDialogue,
    LoadingIcon,
    TileCallToActionMedia,
    VideoMediaIcon,
    FileMediaIcon,
    DblClickElement,
    AudioControl,
    TileItemCheckBox,
    AvatarPill,
    CommentIndicator,
    TileAssetState,
    ActionIcon
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    selectionMode: {
      type: String,
      default: null
    },
    displayActions: {
      type: Boolean,
      default: true
    },
    draggable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useAppStore, ['activeLeftSideBar', 'activeRightSideBar']),
    icon() {
      return icons[this.item.mediaType];
    },
    canEdit() {
      return (
        !this.isDeleted &&
        !this.isShared &&
        !this.isSelectionMode &&
        !this.isReadOnly
      );
    },
    displayActionButton() {
      return (
        !this.disabled &&
        this.displayActions &&
        !this.isDeleted &&
        !this.isShared &&
        !this.selectable &&
        !this.isReadOnly
      );
    },
    backgroundStyle() {
      return this.style || null;
    },
    contentStyle() {
      return this.backgroundStyle || this.fileStyle;
    },
    hasFileBackgroundImage() {
      return !this.backgroundStyle && !!this.fileBackgroundImage;
    },
    hasImageBackground() {
      return !!this.backgroundStyle || !!this.fileBackgroundImage;
    },
    displayAssetState() {
      return this.isProtected || this.isReadOnly || this.isPrivate;
    },
    displayBottom() {
      return this.item?.mediaType !== 'note';
    },
    displayStateAndActions() {
      return (
        !this.isShared && (this.displayAssetState || this.displayActionButton)
      );
    },
    displayNoteStateAndActions() {
      return this.displayStateAndActions && this.item?.mediaType === 'note';
    }
  },
  methods: {
    ...mapActions(useMediaStore, ['track']),
    actionClicked() {
      if (this.disabled || this.draggable) return;
      this.toggleDialogue('options');
    },
    contentClicked() {
      if (this.disabled || this.selectable || this.draggable) return;

      if (this.item.mediaType === 'audio') {
        const { audio } = this.$refs;
        if (audio) {
          audio.togglePlay();
        }
      } else if (this.item.mediaType === 'link') {
        // DO NOTHING
      } else if (this.item.mediaType === 'file') {
        if (this.fileUrl) {
          this.track({
            event: 'file_opened',
            item: this.item,
            isShared: this.isShared
          });
          window.open(this.fileUrl, '_blank');
        }
      } else this.viewMedia();
    },
    mediaLinkClicked() {
      if (this.disabled || this.selectable || this.draggable) return;

      if (this.item.mediaType === 'link') {
        if (this.item.link) {
          this.track({
            event: 'link_opened',
            item: this.item,
            isShared: this.isShared
          });
          window.open(this.item.link, '_blank');
        }
      }
    },
    contentDoubleClicked() {
      if (this.disabled || this.selectable || this.draggable) return;

      if (!this.canEdit) this.viewMedia();

      if (this.item.mediaType === 'text' || this.item.mediaType === 'note')
        this.editMedia();
      else this.viewMedia();
    },
    viewMedia() {
      if (this.disabled || this.selectable || this.draggable) return;

      if (this.item.evaluationId) {
        this.$emit('selected', this.item);
      } else {
        this.$emit('view', this.item);
      }
    },
    editMedia(e) {
      e?.stopPropagation();
      e?.preventDefault();

      if (this.disabled || this.selectable || this.draggable) return;

      if (!this.canEdit) return;
      this.$emit('edit', this.item);
    },
    audioEvent(data) {
      this.track({
        event: `audio_${data.event}`,
        item: this.item,
        isShared: this.isShared,
        currentTime: data.currentTime,
        duration: data.duration
      });
    },
    resize() {
      const { content } = this.$refs;
      const bottomHeight = this.item?.mediaType !== 'note' ? 0 : 73;
      content.$el.style.height = `${content.$el.offsetWidth + bottomHeight}px`;
    }
  },
  mounted() {
    this.$nextTick(async () => {
      setTimeout(() => {
        this.resize();
      }, 100);
    });
  },
  beforeMount() {
    window.addEventListener('resize', this.resize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    item: {
      handler() {
        this.$nextTick(() => {
          this.resize();
        });
      },
      deep: true
    },
    activeLeftSideBar: {
      handler() {
        this.$nextTick(() => {
          this.resize();
        });
      },
      deep: true
    },
    activeRightSideBar: {
      handler() {
        this.$nextTick(() => {
          this.resize();
        });
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';

$tile-padding: 11px;
$tile-bottom-height: 71px;
$text-padding: 38px;
$text-padding-left-right: 30px;

.tile-media {
  @include grid($cols: 1fr, $rows: 1fr auto, $areas: 'content' 'bottom');

  min-width: $tile-media-width;
  min-height: $tile-media-height;
  font-size: $font-size-small;
  color: $font-tile-media-color;
  background-color: $background-tile-media;
  box-shadow: 0px 0px 10px #0000003d;
  border-radius: $tile-media-border-radius;
  position: relative;

  &__content {
    @include grid-item($area: content);
    min-width: $tile-media-width;
    min-height: $tile-media-width;
    overflow: hidden;
    position: relative;

    border-radius: $tile-media-border-radius $tile-media-border-radius 0 0;
    border: 1px solid $background-tile-media;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    // tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;

    &--selection-mode {
      cursor: default;
    }

    &--text,
    &--note,
    &--inner {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &__type--text {
      border: 1px solid $media-text-background-color;
    }

    &__type--note {
      background-color: $media-note-background-color;
      border-radius: $tile-media-border-radius;
      border: 1px solid $media-note-background-color;
    }

    &__type--video {
      background-color: $media-video;
      border: 1px solid $media-video;
    }

    &__type--audio {
      background-color: $media-audio;
      border: 1px solid $media-audio;
    }

    &__type--link {
      border: none;
      border-width: 0;
    }

    &__type--file {
      background-color: $media-file;
      border: 1px solid $media-file;
    }

    &--text {
      padding: $text-padding;
      color: $media-text-font-color;
      background-color: $media-text-background-color;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0px;
      cursor: default;

      &--html {
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-align: left;

        a {
          text-decoration: underline;
        }
      }
    }

    &--note {
      @include flexy($dir: column, $just: center);
      padding: 38px 32px;

      > * {
        width: 100%;
        max-height: 100%;
        overflow: hidden;
        text-align: left;
        font-size: 30px;
        line-height: 36px;
        color: $media-note-font-color;
        white-space: pre-wrap;
        letter-spacing: 0px;
      }
    }

    &__processing {
      @include flexy($dir: column, $align: center);

      &--icon {
        text-align: center;
        width: $loading-size-xsmall;
        height: $loading-size-xsmall;

        > img {
          width: $loading-size-xsmall;
          height: $loading-size-xsmall;
        }
      }

      &--text {
        text-align: center;
      }
    }

    &--inner {
      @include flexy($align: center, $just: center);
    }

    &--audio {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &--video {
      @include flexy($align: center, $just: center);
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      > svg {
        fill: rgba(255, 255, 255, 1);
      }
    }

    &--link {
      width: 100%;
      height: 100%;
      cursor: default;

      > * {
        > .tile-call-to-action-media__boxe {
          border-radius: $tile-media-border-radius $tile-media-border-radius 0 0;
          border: none;
          border-width: 0;
          box-shadow: inset 0px 0px 30px #0000004d;
        }
      }
    }

    &--file {
      @include flexy($align: center, $just: center);
      width: 100%;
      height: 100%;

      &--pill {
        @include flexy($align: center, $just: center);
        width: 53px;
        height: 53px;
        border-radius: 50% 50%;
        background-color: #ffffff;

        > div {
          color: $media-file;
          font-weight: bold;
          text-transform: uppercase;
        }

        > svg {
          fill: $media-file;
        }

        &--has-image-background {
          background-color: $media-file;

          > div {
            color: #ffffff;
          }

          > svg {
            fill: #ffffff;
          }
        }
      }
    }

    &--top-left {
      @include flexy($align: flex-start, $just: center);
      position: absolute;
      left: $spacing-third;
      top: $spacing-third;
      cursor: default;

      > * {
        margin-right: $spacing-third;
      }
    }

    &--has-image-background {
      box-shadow: inset 0px 0px 30px #0000004d;
      border: none;
      border-width: 0;
      background-color: unset;
    }

    &--disabled {
      cursor: default;
    }
  }

  &__bottom {
    @include grid($cols: auto 1fr auto, $areas: 'icon name right');
    @include grid-item($area: bottom);
    min-height: $tile-bottom-height;
    border-radius: 0 0 $tile-media-border-radius $tile-media-border-radius;
    border: 1px solid $background-tile-media;
    padding: $spacing-half;

    &--icon {
      @include grid-item($area: icon);
      @include flexy($align: flex-start);

      > * {
        fill: $icon-tile-media-color;
      }
    }

    &--name {
      @include grid-item($area: name);
      margin-left: $spacing-half;
      height: 53px;
      max-height: 53px;
      overflow-y: hidden;
    }

    &__right {
      @include grid($rows: 1fr 1fr, $areas: 'state' 'options');
      @include grid-item($area: right);
      height: 100%;
      margin-left: $spacing-half;

      user-select: none;

      &--state {
        @include grid-item($area: state, $align: flex-start, $just: flex-end);
        height: auto;

        > .tile-asset-state__info {
          margin-right: 0;
          margin-left: $spacing-quarter;
        }
      }

      &--options {
        @include grid-item($area: options, $align: flex-end, $just: flex-end);
        @include flexy();
        padding-right: 2px;

        > * {
          margin-left: $spacing-half;
        }

        :first-child {
          margin-left: 0;
        }

        > button.warning {
          > * {
            fill: $error-color;
          }
        }
      }
    }
  }

  &__note-state-actions {
    @include grid($cols: 1fr 1fr, $areas: 'state options');
    position: absolute;
    bottom: $spacing-half;
    left: $spacing-half;
    right: $spacing-half;

    &--state {
      @include grid-item($area: state, $align: center, $just: flex-start);

      > .tile-asset-state__info {
        > .icon {
          fill: $media-note-font-color;
        }
      }
    }

    &--options {
      @include grid-item($area: options, $align: center, $just: flex-end);

      > button {
        > .icon {
          fill: $media-note-font-color;
        }
      }
    }
  }

  &--checkbox {
    .tile-item__item-checkbox {
      position: absolute;
      top: -$spacing-third;
      left: -$spacing-third;
    }
  }

  &--hidden {
    opacity: $disable-opacity;
  }

  &--disabled {
    background: #000000 0% 0% no-repeat padding-box;
    opacity: $disable-darker-opacity;
  }
}
</style>
